import {useTranslation} from 'react-i18next';
import {useUser, useUserUpsertMutation} from './useApi';
import {useHistory, useParams} from 'react-router-dom';
import {useAuth} from './useAuth';
import {useUserFullName, useCase, useCaseUpsertMutation} from './useApi';
import {useEffect, useState} from 'react';
import {AppScreen} from './AppScreen';
import {Form} from './Form';
import {Headline} from './Headline';
import {Select} from './Select';
import {Option} from './Option';
import {LocalFileImportForm} from './LocalFileImportForm';
import {CombImportForm} from './CombImportForm';

export const ImportDialog = ({doFilesImport, cancelImport}) => {
  const {t} = useTranslation();
  const user = useUser();
  const userUpsertMutation = useUserUpsertMutation();
  const [importSource, setImportSource] = useState(user.importSource || "LocalFile");

  useEffect(() => {
    if (importSource && user.importSource != importSource) {
      userUpsertMutation.mutate({
        ...user,
        importSource,
      });
    }
  }, [importSource]);

  const children = (
    <Select name="source" value={importSource} placeholder={false} onChange={setImportSource}>
      <Option key="localFile" value="LocalFile"/>
      <Option key="comb" value="Comb"/>
    </Select>
  );

  const formProps = {
    i18nScopes: ['ImportDialog'],
    children,
    doFilesImport,
    cancelImport,
  };

  const renderImportSourceForm = () => {
    switch (importSource) {
      case "Comb":
        return <CombImportForm {...formProps}/>;
      default:
        return <LocalFileImportForm {...formProps}/>
    }
  };

  return (
    <div className="ImportDialog">
      <Headline>{t('ImportDialog.headline')}</Headline>
      {renderImportSourceForm()}
    </div>
  );
};

export default ImportDialog;
