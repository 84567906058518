import {useTranslation} from 'react-i18next';
import {useContext, useState, useEffect, useRef} from 'react';
import MUICheckbox from 'muicss/lib/react/checkbox';
import {useI18nScopes, useFieldReducer, useFocusNext} from './Form';
import './Checkbox.css';

export const Checkbox = (props) => {
  const i18nScopes = useI18nScopes().map(s => s + '.' + props.name);
  const {t} = useTranslation();

  const ref = useRef();
  const focusNext = useFocusNext(props.name);

  const [state, dispatch] = useFieldReducer({
    ...props,
    focus: () => {
      ref.current.controlEl.focus();
    },
  });

  useEffect(() => {
    dispatch({
      name: 'valuesChanged',
      savedValue: props.savedValue,
      value: props.value,
    });
  }, [props.savedValue, props.value]);

  const onChange = (e) => {
    dispatch({
      name: 'valueChangedByUser',
      value: e.target.checked,
    });
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(state.value);
    }
  }, [state.value]);

  const muiCheckboxProps = {
    name: props.name,
    type: props.type,
    checked: !!state.value,
    required: props.required,
    disabled: props.disabled,
    ref,
    onChange,
  };

  if (props.label !== false) {
    muiCheckboxProps.label = props.label || t([].concat(...i18nScopes.map(s => [s + '.label', s]))) + (props.required ? ' *' : '');
  }

  return (
    <div className={'Checkbox ' + (props.className || '')}>
      <MUICheckbox {...muiCheckboxProps}/>
    </div>
  );
};

export default Checkbox;
