import {useTranslation} from 'react-i18next';
import {Suspense, useState, useEffect, useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query'
import {useUser, useUserQueryKey, useUserUpsertMutation, useApiQuery} from './useApi';
import {DataTableSelect} from './DataTableSelect';
import {Form, useSubmit} from './Form';
import {Input} from './Input';
import {Select} from './Select';
import {Option} from './Option';
import {Checkbox} from './Checkbox';
import {QueryScope} from './QueryScope';
import {Spinner} from './Spinner';
import fetch from 'cross-fetch';

const makeCombQueryKey = request => ['comb', ...request.path, {/* parameters */}, request.body];

const useCombApi = (request, options, parseResponse, canonicalize) => {
  const queryKey = makeCombQueryKey(request);
  const query = useApiQuery(queryKey, canonicalize, {
    ...(options ? options : {}),
  }, parseResponse);
  return query;
}

export const CombLogin = ({request, onLogin}) => {
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const userQueryKey = useUserQueryKey();

  const login = useCombApi(request, {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (login.isSuccess) {
      const newUserData = login.data;
      queryClient.setQueryData(userQueryKey, newUserData);
      onLogin(newUserData.defaultCombAccountId);
    }
  }, [login.isSuccess]);

  // TODO: i18n?
  return login.isError ? <div>Login failed: {JSON.stringify(login.error)}</div> : null;
};

export const CombLoginForm = ({i18nScopes, children, values, onLogin, onCancel}) => {
  const {t} = useTranslation();
  const user = useUser();
  const queryClient = useQueryClient();

  const [loginCredentials, setLoginCredentials] = useState(null);

  const makeLoginRequest = credentials => ({
    path: ['login'],
    body: credentials,
  });

  const onSubmit = values => {
    const newCredentials = {
      email: values.email,
      password: values.password,
    };
    setLoginCredentials(newCredentials);
    queryClient.invalidateQueries({queryKey: makeCombQueryKey(makeLoginRequest(newCredentials))});
  };

  const formProps = {
    i18nScopes: [
      ...(i18nScopes || []),
      'CombLoginForm',
    ],
    values,
    defaultValues: {
      email: user.email,
    },
    onSubmit,
    onCancel,
  };

  return (
    <Form {...formProps}>
      {children}
      <Input name="email" type="email" required={true}/>
      <Input name="password" type="password"/>
      <QueryScope>
        {loginCredentials ? <CombLogin {...{request: makeLoginRequest(loginCredentials), onLogin}}/> : null}
      </QueryScope>
    </Form>
  );
};

export const CombRemoveAccountForm = ({i18nScopes, children, values, accountMap, onSubmit, onCancel}) => {
  const {t} = useTranslation();

  const formProps = {
    i18nScopes: [
      ...(i18nScopes || []),
      'CombRemoveAccountForm',
    ],
    values,
    checkValidity: values => Object.entries(accountMap).map(([key, value]) => values[key]).reduce((a, x) => a || x),
    onSubmit,
    onCancel,
  };

  return (
    <Form {...formProps}>
      {children}
      {Object.entries(accountMap).map(([key, value]) => <Checkbox key={key} name={key} label={value.email}/>)}
    </Form>
  );
};

const CombScanTable = ({i18nScopes, selectedCombAccountId}) => {
  const {t} = useTranslation();

  const scansQuery = useCombApi({
    path: [selectedCombAccountId, 'scans'],
  });

  const columns = [
    /*
       "file_path": "scans/JTes_2021_10_27_093625.obj",
       "notes": "",
       "app_version": "1.0.5",
       "file_units": "m",
       "date_created": 1635306695,
       "patient_first_name": "J",
       "duration": 65,
       "deleted": false,
       "app_build": 19,
       "preset_name": "BK",
       "patient_scan_index": 0,
       "name": "JTes",
       "patient_last_name": "Tes",
       "clinician_first_name": "",
       "file_format": "OBJ",
       "clinician_last_name": "",
       "id": "TlTqupcQQ0zNDleucXox"
     */
    {
      accessor: 'name',
    },
    {
      accessor: 'preset_name',
    },
    {
      accessor: 'date_created',
      Cell: ({value}) => value ? new Date(value * 1000).toLocaleString() : '',
      sortType: 'basic',
    },
  ];

  const submit = useSubmit();

  const onRowDoubleClicked = (row) => {
    submit();
  }

  const tableProps = {
    i18nScopes: [
      ...(i18nScopes || []),
      'CombScanTable',
    ],
    columns,
    data: scansQuery.data.data,
    onRowDoubleClicked,
  };

  return <DataTableSelect name="selectedScans" {...tableProps}/>;
};

export const CombScanFilesDownload = ({selectedCombAccountId, submittedValues, doFilesImport}) => {

  const fileQueries = (submittedValues.selectedScans || []).map(scan => {
    const {id, file_path, file_format} = scan;
    return useCombApi(
      {path: [selectedCombAccountId, 'scans', id, 'download']},
      null,
      async response => await response.blob(),
      blob => new File([blob], file_path, {type: file_format}),
    );
  });

  useEffect(() => {
    doFilesImport({
      ...submittedValues,
      files: fileQueries.map(({data}) => data),
      metadata: submittedValues.selectedScans.map(scan => ({
        units: scan.file_units,
      })),
    });
  }, []);

  return <Spinner/>;
};

export const CombScanListForm = ({i18nScopes, children, values, selectedCombAccountId, doFilesImport, onCancel}) => {

  const [submittedValues, setSubmittedValues] = useState(null);

  const onSubmit = values => {
    setSubmittedValues(values);
  };

  const formProps = {
    i18nScopes: [
      ...(i18nScopes || []),
      'CombScanListForm',
    ],
    values,
    selectedCombAccountId,
    onSubmit,
    onCancel,
    checkValidity: values => values.selectedScans && values.selectedScans.length,
    disabled: !!submittedValues,
  };

  const tableProps = {
    i18nScopes: formProps.i18nScopes,
    selectedCombAccountId,
  }

  const downloadProps = {
    selectedCombAccountId,
    submittedValues,
    doFilesImport,
  }

  return (
    <QueryScope>
      <Form {...formProps}>
        {children}
        <CombScanTable {...tableProps}/>
        {submittedValues ? <CombScanFilesDownload {...downloadProps}/> : null}
      </Form>
    </QueryScope>
  );
};

export const CombImportForm = ({i18nScopes, children: suppliedChildren, doFilesImport, cancelImport}) => {
  const {t} = useTranslation();
  const user = useUser();
  const userUpsertMutation = useUserUpsertMutation();

  const accountMap = user.combAccounts || {};

  const [selectedCombAccountId, setSelectedCombAccountId] = useState(() => {
    const defaultCombAccountId = user.defaultCombAccountId;
    if (defaultCombAccountId && defaultCombAccountId in accountMap) {
      return defaultCombAccountId;
    }
    return 'addAccount';
  });

  useEffect(() => {
    if (selectedCombAccountId && selectedCombAccountId in accountMap) {
      if (selectedCombAccountId != user.defaultCombAccountId) {
        userUpsertMutation.mutate({
          ...user,
          defaultCombAccountId: selectedCombAccountId,
        });
      }
    }
  }, [selectedCombAccountId, user]);

  const onSelectionChange = value => {
    setSelectedCombAccountId(value);
  };

  const onRemove = useCallback(values => {
    const newAccountMap = {...accountMap};
    for (const key in accountMap) {
      if (values[key]) {
        delete newAccountMap[key];
      }
    }
    const newUserData = {
      ...user,
      combAccounts: newAccountMap,
    };

    if (newUserData.defaultCombAccountId && values[newUserData.defaultCombAccountId]) {
      const remainingAccountIds = Object.keys(newAccountMap);
      if (remainingAccountIds.length == 1) {
        setSelectedCombAccountId(remainingAccountIds[0]);
        newUserData.defaultCombAccountId = remainingAccountIds[0];
      } else {
        setSelectedCombAccountId(remainingAccountIds.length == 0 ? 'addAccount' : 'selectAccount');
        delete newUserData.defaultCombAccountId;
      }
    }

    userUpsertMutation.mutate(newUserData);
  }, [user]);

  const onCancel = () => {
    switch (selectedCombAccountId) {
      case 'addAccount':
      case 'removeAccount':
        if (user.defaultCombAccountId in accountMap) {
          setSelectedCombAccountId(user.defaultCombAccountId);
          break;
        } else {
          cancelImport();
        }
      default:
        cancelImport();
        break;
    }
  };

  const onLogin = (accountId) => {
    setSelectedCombAccountId(accountId);
  };

  const children = (
    <>
      {suppliedChildren}
      <Select name="selection" placeholder={false} onChange={onSelectionChange}>
        {Object.entries(accountMap).map(([key, value]) => <Option key={key} value={key} label={value.email}/>)}
        <Option key="addAccount" value="addAccount" label={t('CombImportForm.addAccount')}/>
        {Object.keys(accountMap).length > 0 && <Option key="removeAccount" value="removeAccount" label={t('CombImportForm.removeAccount')}/>}
      </Select>
    </>
  );

  const formProps = {
    i18nScopes: [
      ...(i18nScopes || []),
      'CombImportForm',
    ],
    children,
    values: {
      selection: selectedCombAccountId,
    },
  };

  switch (selectedCombAccountId) {
    case 'addAccount':
      return <CombLoginForm {...formProps} {...{onLogin, onCancel}}/>;
    case 'removeAccount':
      return <CombRemoveAccountForm {...formProps} {...{accountMap, selectedCombAccountId, setSelectedCombAccountId, onSubmit: onRemove, onCancel}}/>;
    case null:
      return <Form {...formProps}>{children}<div>{t('CombImportForm.pleaseSelectAccount')}</div></Form>;
    default:
      if (selectedCombAccountId in accountMap) {
        return <CombScanListForm {...formProps} {...{selectedCombAccountId, doFilesImport, onCancel}}/>;
      } else {
        return <Spinner/>;
      }
  }
};

export default CombImportForm;
