import {Spinner} from './Spinner';
import {App} from './App';
import {useState, useEffect, useContext} from 'react';
import {QueryScope} from './QueryScope';
import {AuthProvider} from './useAuth';
import {BrowserRouter} from 'react-router-dom';
import {WorkspaceContext} from './WorkspaceContext';
import 'muicss/dist/css/mui.min.css';
import './index.css';

export const Top = ({mockMsal}) => {
  const [workspaceDestroyed, setWorkspaceDestroyed] = useState(false);
  const [workspaceDestructionChecked, setWorkspaceDestructionChecked] = useState(false);

  const workspaceContext = useContext(WorkspaceContext);
  workspaceContext.workspaceDestroyed = () => {
    setWorkspaceDestroyed(true);
  };

  useEffect(() => {
    if (workspaceDestroyed) {
      window.location.reload();
    }
    setWorkspaceDestructionChecked(true);
  }, [workspaceDestroyed, workspaceDestructionChecked]);
  return (
    (workspaceDestroyed || !workspaceDestructionChecked) ? <Spinner/> :
    <QueryScope>
      <BrowserRouter>
        <AuthProvider mockMsal={mockMsal}>
          <App/>
        </AuthProvider>
      </BrowserRouter>
    </QueryScope>
  );
};
