import {useTranslation} from 'react-i18next';
import {useRef} from 'react';
import {Form} from './Form';
import {FileInput} from './FileInput';

export const LocalFileImportForm = ({i18nScopes, children, doFilesImport, cancelImport}) => {
  const filesRef = useRef();
  const setFiles = (files) => {
    filesRef.current = files;
  };

  const onSubmit = values => {
    doFilesImport({...values, files: filesRef.current || values.files});
  };

  const formProps = {
    i18nScopes: [
      ...(i18nScopes || []),
      'LocalFileImportForm'
    ],
    onSubmit,
    onCancel: cancelImport,
  };
  return (
    <Form {...formProps}>
      {children}
      <FileInput name="files" accept=".obj,model/obj,.stl,model/stl,.ply,.sgl" onChange={setFiles}/>
    </Form>
  );
};

export default LocalFileImportForm;
