import {useMemo, useCallback, useEffect} from 'react';
import {useEffectAfterFirstRender} from './useEffectAfterFirstRender';
import {useTranslation} from 'react-i18next';
import {useTable, useSortBy, useRowSelect} from 'react-table'
import './DataTable.css';

export const DataTable = (props) => {
  const {t} = useTranslation();

  const i18nScopes = [...(props.i18nScopes || []), 'DataTable'];

  const columns = useMemo(
    () => props.columns.map(c => ({
      Header: t(i18nScopes.map(s => s + '.' + (c.id || c.accessor))),
      ...c,
    })),
    [props.i18nScopes, props.columns]
  );

  const stateReducer = (state, action) => {
    if (!props.multiple && action.type === 'toggleRowSelected' && Object.keys(state.selectedRowIds).length) {
      const newState = { ...state };
      newState.selectedRowIds = {
        [action.id]: true,
      };
      return newState;
    }
    return state;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: props.data,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      stateReducer,
      initialState: {
        selectedRowIds: props.selectedRowIds || {},
      },
    },
      useSortBy,
      useRowSelect,
  );

  const getGeneralRowProps = (row) => {
    var generalRowProps = {};
    if (props.onRowClicked) {
      generalRowProps.onClick = () => props.onRowClicked(row.original);
    } else if (props.onSelectedRowsChange) {
      if (row.isSelected) {
        generalRowProps.className = 'rowSelected';
      }
      generalRowProps.onClick = () => row.toggleRowSelected();
    }
    if (props.onRowDoubleClicked) {
      if (props.onSelectedRowsChange) {
        generalRowProps.onDoubleClick = () => {
          row.toggleRowSelected(true);
          props.onSelectedRowsChange([row.original]);
          props.onRowDoubleClicked(row.original);
        }
      } else {
        generalRowProps.onDoubleClick = () => props.onRowDoubleClicked(row.original);
      }
    }
    return generalRowProps;
  };

  useEffectAfterFirstRender(() => {
    if (props.onSelectedRowsChange) {
      props.onSelectedRowsChange(selectedFlatRows.map(r => r.original));
    }
  }, [selectedRowIds]);

  return (
    <table className="DataTable mui-table mui-table--bordered" {...getTableProps()}>
      <thead className="unselectable">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className="columnHeading">
                  {column.render('Header')}
                </div>
                {rows.length > 1 &&
                  <div className="sortIndicator" style={column.isSorted ? {} : {color: '#00000030'}}>
                    {column.isSorted ? (column.isSortedDesc ? '↑' : '↓') : '↕'}
                  </div>
                }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...getGeneralRowProps(row)} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
