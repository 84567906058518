import {useEffect, useRef, useCallback, useState, useMemo} from 'react';
import {useQuery} from 'react-query';
import {useCase, useCaseUpsertMutation, useTTMeasurements, useTTMeasurementsUpsertMutation} from './useApi';
import {suspend} from './suspend';
import {Modal} from './Modal';
import {ImportDialog} from './ImportDialog';
import './WorkspaceInstance.css';

const loadWorkspaceModule = suspend(import('@operationnamaste/workspace/module.js'));

export const WorkspaceInstance = ({caseId}) => {
  const rootContainerRef = useRef();
  const viewportRef = useRef();
  const canvasRef = useRef();
  const backgroundopenRef = useRef();
  const alphaopenRef = useRef();
  const textureopenRef = useRef();
  const matcapopenRef = useRef();
  const caseInfo = useCase(caseId) || {};
  const caseInfoMutation = useCaseUpsertMutation(caseId) || {};
  const measurements = useTTMeasurements(caseId) || {};
  const measurementsMutation = useTTMeasurementsUpsertMutation(caseId);
  const [sgl, setSgl] = useState(null);

  const updateCaseInfo = useCallback(newCaseInfo => {
    const updated = {
      ...caseInfo,
      ...newCaseInfo
    };
    caseInfoMutation.mutate(updated);
  }, [caseInfo]);

  const updateMeasurements = useCallback(newMeasurements => {
    const updated = {
      ...measurements,
      ...newMeasurements
    };
    measurementsMutation.mutate(updated);
  }, [measurements]);

  const {SculptGL} = loadWorkspaceModule();

  const [showImportDialog, setShowImportDialog] = useState(false);
  const fileopenChangeHandlerRef = useRef();

  const doFilesImport = ({files, metadata}) => {
    fileopenChangeHandlerRef.current({
      stopPropagation: () => {},
      preventDefault: () => {},
      dataTransfer: {files},
      metadata,
    });
    setShowImportDialog(false);
  };

  const cancelImport = () => {
    setShowImportDialog(false);
  };

  const fileopenAdapter = {
    value: '',
    click: () => setShowImportDialog(true),
    addEventListener: (eventType, handler, useCapture) => {
      if (eventType == 'change') {
        fileopenChangeHandlerRef.current = handler;
      }
    },
  };

  useEffect(() => {
    const sgl = new SculptGL({
      rootContainer: rootContainerRef.current,
      viewport: viewportRef.current,
      canvas: canvasRef.current,
      fileopen: fileopenAdapter,
      backgroundopen: backgroundopenRef.current,
      alphaopen: alphaopenRef.current,
      textureopen: textureopenRef.current,
      matcapopen: matcapopenRef.current,
      caseId,
    });
    sgl.start();
    setSgl(sgl);
    return () => {
      sgl.close();
      setSgl(null);
    };
  }, []);

  useEffect(() => {
    if (sgl) {
      sgl.setCaseInfoState(caseInfo, updateCaseInfo);
      sgl.setMeasurementsState(measurements, updateMeasurements);
    }
  }, [sgl, caseInfo, updateCaseInfo, measurements, updateMeasurements]);

  return (
    <div>
      <div ref={rootContainerRef} className="WorkspaceInstance SculptGL rootContainer">
        <input type='file' ref={backgroundopenRef} className="SculptGL backgroundopen" style={{display: 'none'}}/>
        <input type='file' ref={alphaopenRef} className="SculptGL alphaopen" style={{display: 'none'}}/>
        <input type='file' ref={textureopenRef} className="SculptGL textureopen" style={{display: 'none'}}/>
        <input type='file' ref={matcapopenRef} className="SculptGL matcapopen" style={{display: 'none'}}/>
        <div ref={viewportRef} className="SculptGL viewport">
          <canvas ref={canvasRef}></canvas>
        </div>
      </div>
      {showImportDialog && <div className="AppScreen"><div className="AppScreenArea"><Modal><ImportDialog caseId={caseId} doFilesImport={doFilesImport} cancelImport={cancelImport}/></Modal></div></div>}
    </div>
  );
};

export default WorkspaceInstance;
