import {useRef, useEffect} from 'react';

export const useEffectAfterFirstRender = (func, values) => {
  const isFirstRender = useRef(true);
  useEffect(
    () => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
      } else {
        func();
      }
    },
    values,
  );
};

export default useEffectAfterFirstRender;
