import {useTranslation} from 'react-i18next';
import {useEffect, useState, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {Children} from 'react';
import {Form} from './Form';
import {Input} from './Input';
import {Select} from './Select';
import {Option} from './Option';
import {lengthUnits} from './lengthUnits';
import {DataTable} from './DataTable';

const columnIds = [
  'Patient',
  'Model',
  'Goal',
  'Interim',
  'Final',
];

const rowIds = [
  'PTB',
  'Circ1',
  'Circ2',
  'Circ3',
  'Circ4',
  'Circ5',
  'Circ6',
  'AP',
  'ML',
  'MLfib',
  'SCML',
  'TibL',
  'RLL',
];

const data = rowIds.map(rowId => ({id: rowId}));

const fieldOrder = [
  'Units',
  'PercentRedux',
  ...rowIds.map((r) => columnIds.map((c) => r + c)),
  'OutcomeNotes',
];

const EditableCell = ({row, cell, value}) => {
  const name = row.original.id + cell.column.id;
  // Input gets its value from the Form
  return (
    <Input key={name} name={name} label={false} type="number" step="any"
           placeholder={cell.column.calcPlaceholder(row.original.id, cell.column.id)}/>
  );
};

export const TTMeasurementForm = (props) => {
  const {t} = useTranslation();
  const [values, setValues] = useState(props.values || {});

  const calcPlaceholder = (rowId, columnId) => {
    if (columnId == 'Goal') {
      const patient = parseFloat(values[rowId+'Patient'] || '0');
      if (rowId == 'PTB' || rowId.startsWith('Circ')) {
        const percentRedux = parseFloat(values.PercentRedux || '0');
        const s = String(patient - (patient*percentRedux/100.0));
        return s == '0' ? '0.0' : s;
      }
      const s = String(patient);
      return s == '0' ? '0.0' : s;
    }
    return '0.0';
  };

  const columns = useMemo(() => [
    {
      id: 'measurement',
      accessor: row => t('TTMeasurementForm.' + row.id),
    },
    ...columnIds.map(colId => ({
      id: colId,
      accessor: row => values[row.id + colId],
      Cell: EditableCell,
      calcPlaceholder,
    })),
  ], [values /* else placeholders will not be recalculated */]);

  const i18nScopes = [...(props.i18nScopes || []), 'TTMeasurementForm'];

  const onChange = (values) => {
    setValues(values);
  };

  return (
    <Form i18nScopes={i18nScopes} savedValues={props.values} onChange={onChange} onSubmit={props.onSubmit} mutation={props.mutation} fieldOrder={fieldOrder}>
      <Select name="Units" defaultValue='cm' required={true}>
        {lengthUnits.map((value) => <Option key={value} label={t('lengthUnits.' + value)} value={value}/>)}
      </Select>
      <Input name="PercentRedux" type="number" step="any" required={true}/>
      <DataTable
        i18nScopes={i18nScopes}
        columns={columns}
        data={data}
      />
      <Input name="OutcomeNotes"/>
    </Form>
  );
};


export default TTMeasurementForm;
