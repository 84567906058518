import {useRef, forwardRef, useEffect} from 'react';
import MUIButton from 'muicss/lib/react/button';
import './Button.css';

export const Button = forwardRef((props, ref) => {
  const wrapperRef = useRef();
  useEffect(() => {
    if (ref) {
      ref.current = wrapperRef.current.firstElementChild;
    }
  });
  return (
    <div className={'Button ' + (props.className || '')} ref={wrapperRef}>
      <MUIButton className="actionFont" onClick={props.onClick} disabled={props.disabled}>
        {props.children}
      </MUIButton>
    </div>
  );
});

export default Button;
