import {useState, useEffect} from 'react';
import {useEffectAfterFirstRender} from './useEffectAfterFirstRender';
import {useFieldReducer} from './Form';
import {DataTable} from './DataTable';

export const DataTableSelect = (props) => {
  const [state, dispatch] = useFieldReducer(props);

  useEffectAfterFirstRender(() => {
    if (props.onChange) {
      props.onChange(state.value);
    }
  }, [state.value]);

  const onSelectedRowsChange = (rows) => {
    dispatch({
      name: 'valueChangedByUser',
      value: rows,
    });
  };

  const tableProps = {
    ...props,
    onSelectedRowsChange,
  };

  return (
    <DataTable {...tableProps}>
      {props.children}
    </DataTable>
  );
};

export default DataTableSelect;
