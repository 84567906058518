import { v4 as uuidv4 } from 'uuid';
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {AuthStatus, useAuth} from './useAuth';
import {URL} from 'url-ponyfill';
import fetch from 'cross-fetch';

const baseUrl = new URL('/api/', window.location.origin);

function isString(x) {
  return typeof x === 'string' || x instanceof String;
}

function splitQueryKey(queryKey) {
  const queryKeyArray = Array.isArray(queryKey) ? queryKey : [queryKey];
  let i = 0;
  while (i < queryKeyArray.length && isString(queryKeyArray[i])) {
    i++;
  }
  const pathComponents = queryKeyArray.slice(0, i);
  var parameters = null;
  var queryKeyBody = null;
  if (i < queryKeyArray.length) {
    parameters = queryKeyArray[i];
    i++;
    if (i < queryKeyArray.length) {
      queryKeyBody = queryKeyArray[i];
    }
  }
  return {
    pathComponents,
    parameters,
    queryKeyBody
  };
}

function buildRequestFromQueryKey(queryKey) {
  const {pathComponents, parameters, queryKeyBody} = splitQueryKey(queryKey);
  const url = new URL(pathComponents.map(encodeURIComponent).join('/'), baseUrl);
  if (parameters && Object.keys(parameters).length > 0) {
    const sortedParams = Object.entries(parameters).sort((a, b) => a[0] < b[0] ? -1 : (a[0] > b[0] ? 1 : 0));
    const urlSearchParams = new URLSearchParams();
    for (const [name, value] of sortedParams) {
      urlSearchParams.append(name, value);
    }
    url.search = '?' + urlSearchParams.toString();
  }
  return {url, queryKeyBody};
}

var lastQueryNumber = 0;

async function queryApi(auth, queryKey, canonicalize, postBody, parseResponse) {
  const {url, queryKeyBody} = buildRequestFromQueryKey(queryKey);
  const body = postBody || queryKeyBody;
  const accessToken = await auth.getAccessToken();
  if (!accessToken) {
    throw new Error("Access token not available");
  }

  const queryNumber = ++lastQueryNumber;

  const response = await fetch(url, {
    method: body ? 'POST' : 'GET',
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      ...(body ? {'Content-Type': 'application/json'} : {}),
    },
    ...(body ? {body: JSON.stringify(body)} : {}),
  });

  if (response.status >= 500) {
    throw new Error(`Server error: ${response.status} ${response.statusText}`);
  }
  if (response.status == 404) {
    return null;
  }
  if (response.status >= 400) {
    throw new Error(`Invalid request: ${response.status} ${response.statusText}`);
  }
  if (!response.ok) {
    throw new Error(`Unexpected fetch error: ${response.status} ${response.statusText}`);
  }

  if (!parseResponse) {
    parseResponse = async r => await r.json();
  }
  const result = await parseResponse(response);

  return canonicalize ? canonicalize(result) : result;
};

export const useApiQuery = (queryKey, canonicalize, options, parseResponse) => {
  const auth = useAuth();
  const query = useQuery(queryKey, ({queryKey}) => queryApi(auth, queryKey, canonicalize, undefined, parseResponse), {
    ...(options ? options : {}),
    enabled: auth.status == AuthStatus.LOGGED_IN && (!options || !('enabled' in options) || !!options.enabled),
  });
  return query;
};

const useApiMutation = (queryKey, canonicalize, options, parseResponse) => {
  const auth = useAuth();
  return useMutation(data => queryApi(auth, queryKey, canonicalize, data, parseResponse), options);
};

export const useApiUpsertMutation = (queryKey, canonicalize) => {
  const queryClient = useQueryClient();
  return useApiMutation(queryKey, canonicalize, {
    onSuccess: data => {
      queryClient.invalidateQueries(queryKey[0]);
      queryClient.setQueryData(queryKey, data);
    },
  });
};

const orLoggedInUser = (userId) => {
  const auth = useAuth();
  return userId || auth.userId;
};

const canonicalizeUser = (user) => ({
  ...user,
  Default_Role: user.Default_Role && user.Default_Role !== '' ? user.Default_Role : 'New',
});

export const useUserQueryKey = (userId) => (
  ['user', orLoggedInUser(userId)]
);

export const useUser = (userId) => {
  const query = useApiQuery(useUserQueryKey(userId), canonicalizeUser);
  return query.data;
};

export const useUserUpsertMutation = (userId) => {
  return useApiUpsertMutation(useUserQueryKey(userId), canonicalizeUser);
};

export const useUsers = (filter) => {
  return useApiQuery(['user', filter], a => a.map(canonicalizeUser)).data;
};

export const useUserFullName = (userId) => {
  const user = useUser(userId);
  const fullName = [];
  if (user.Prefix && user.Prefix.length > 0) {
    fullName.push(user.Prefix);
  }
  if (user.FirstName && user.FirstName.length > 0) {
    fullName.push(user.FirstName);
  }
  if (user.LastName && user.LastName.length > 0) {
    fullName.push(user.LastName);
  }
  return fullName.join(' ');
}

export const useInstructorRole = (userId) => {
  return useApiQuery(['instructor', orLoggedInUser(userId)]).data;
};

export const useInstructorRoleUpsertMutation = (userId) => {
  return useApiUpsertMutation(['instructor', orLoggedInUser(userId)]);
};

export const useCase = (caseId) => {
  return useApiQuery(['case', caseId]).data;
};

export const useCaseUpsertMutation = (caseId) => {
  return useApiUpsertMutation(['case', caseId]);
};

export const useCaseInsertMutation = () => {
  return useCaseUpsertMutation(uuidv4());
};

export const useCases = (filter) => {
  return useApiQuery(['case', filter]).data;
};

export const useTTMeasurements = (caseId) => {
  return useApiQuery(['ttmeasure', caseId]).data;
};

export const useTTMeasurementsUpsertMutation = (caseId) => {
  return useApiUpsertMutation(['ttmeasure', caseId]);
};
