import {useTranslation} from 'react-i18next';
import {useRef} from 'react';
import MUIInput from 'muicss/lib/react/input';
import {useI18nScopes} from './Form';
import './Input.css';

export const FileInput = (props) => {
  const i18nScopes = useI18nScopes().map(s => s + '.' + props.name);
  const {t} = useTranslation();

  const onChange = event => {
    event.stopPropagation();
    event.preventDefault();
    props.onChange(event.dataTransfer ? event.dataTransfer.files : event.target.files);
  };

  const muiInputProps = {
    ...props,
    onChange
  };

  if (props.label !== false) {
    muiInputProps.label = props.label || t([].concat(...i18nScopes.map(s => [s + '.label', s]))) + (props.required ? ' *' : '');
  }

  return (
    <div className={'FileInput Input ' + (props.className || '')}>
      <MUIInput type="file" {...muiInputProps}/>
    </div>
  );
};

export default FileInput;
